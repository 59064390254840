<template>
  <div class="home page-wrapper">
    <div class="page-body">
      <div class="container-xl" v-if="loaded">
        <div class="row row-cards">
          <div class="col-lg-6">
            <div class="card mb-2">
              <div class="card-body">
                <div class="text-center">
                  <a :href="`https://${site.directoryurl}/`" target="_blank">
                    <img
                      :src="`https://cdn.mediabrains.com/directorylogos/${site.projectname}_pubhome.gif`"
                    />
                  </a>

                  <div class="subheader mb-2">
                    {{ site.sitename }} Featured Listing Benefits
                  </div>
                </div>

                <ul class="list-group mb-3">
                  <li
                    class="list-group-item d-flex align-items-center lh-condensed"
                    v-for="benefit in benefits"
                    v-bind:key="benefit"
                  >
                    <div class="pe-4">
                      <div class="bg-lime-lt avatar">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="icon icon-tabler icon-tabler-check"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          stroke-width="2"
                          stroke="currentColor"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path
                            stroke="none"
                            d="M0 0h24v24H0z"
                            fill="none"
                          ></path>
                          <path d="M5 12l5 5l10 -10"></path>
                        </svg>
                      </div>
                    </div>

                    <div>
                      <span>{{ benefit }}</span>
                    </div>
                  </li>
                </ul>

                <div class="subheader mb-2">Customer Testimonial</div>
                <blockquote>
                  <span class="italic">
                    "I started small with MediaBrains and waited to see some
                    results. We did some Facebook advertising. We spent a lot of
                    money on Google AdWords, but we weren’t seeing the results.
                    <b>Now we’re all in with MediaBrains</b> – we do display
                    advertising, featured content, and sponsored eNewsletters.
                    We do just about everything we can,
                    <b>and the results are there.</b>"
                  </span>
                </blockquote>
                <div class="d-flex flex-row-reverse">
                  <div class="d-flex align-items-center text-reset lh-1">
                    <span class="avatar avatar-sm">JB</span>
                    <div class="ps-2">
                      <div>
                        <b>John Beck</b>
                        <span class="text-muted">&nbsp; President/CEO</span>
                      </div>
                      <div class="mt-1 small text-muted">
                        The Assessment Company&reg;
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="card mb-3">
              <div class="table-responsive mb-0">
                <table class="table table-vcenter card-table mb-0">
                  <thead>
                    <tr>
                      <th>Item</th>
                      <th class="text-right">Cost</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <div>Featured Upgrade</div>
                        <div>
                          <small class="text-muted">
                            {{ site.sitename }}: 1 year upgrade
                          </small>
                        </div>
                      </td>
                      <td class="text-right">
                        {{ money(site.annualDirectoryCost) }}
                      </td>
                    </tr>
                    <tr class="bg-green-lt" v-if="rebate">
                      <td>
                        <div>Plus Listing payment credit</div>
                        <div>
                          <small class="text-muted"> </small>
                        </div>
                      </td>
                      <td class="text-right">
                        {{ money(this.rebate) }}
                      </td>
                    </tr>
                    <tr class="bg-gray">
                      <td><b>Total</b></td>
                      <td class="text-right">{{ money(total_price) }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <checkout-form
              :submitting-payment="submittingPayment"
              :price="parseInt(total_price * 100)"
              :success="paymentSuccess"
              :error="paymentError"
              v-on:update:checkout-card="checkout"
            ></checkout-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.italic {
  font-style: italic;
}
</style>

<script>
import UpsellService from "../services/UpsellService";
import CheckoutForm from "../components/Upgrade/CheckoutForm";

export default {
  name: "Upgrade",
  components: {
    CheckoutForm,
  },
  data: () => {
    return {
      siteId: -1,
      site: null,
      siteSeo: null,
      loaded: false,
      rebate: null,
      incident: null,
      plus: false,

      submittingPayment: false,
      paymentSuccess: false,
      paymentError: false,

      checkoutCard: {},
    };
  },
  computed: {
    benefits() {
      return [
        "First page listing placement above all basic listings.",
        `Unlimited category listings on the ${this.site.sitename}.`,
        "Direct links on profile to your company's website and social media.",
        "Generate leads with unlimited products, white papers and press releases.",
        "Access to complete visitor insights including domains and interests.",
      ];
    },
    cc_expiration() {
      if (this.form.expiration_month && this.form.expiration_year) {
        return `${this.form.expiration_month}/${this.form.expiration_year}`;
      } else {
        return "";
      }
    },
    cc_years() {
      let y = new Date();
      let years = [];
      for (var i = 0; i < 10; i++) {
        years.push(y.getFullYear() + i);
      }
      return years;
    },
    total_price() {
      let cost = this.site.annualDirectoryCost;
      if (this.rebate) {
        return cost + this.rebate;
      }
      return cost;
    },
  },
  methods: {
    money(amount) {
      var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      });

      return formatter.format(amount);
    },
    async checkout(card) {
      this.submittingPayment = true;
      let company = this.$auth.activeCompany;
      try {
        let paymentResult = await UpsellService.checkout(
          company,
          { siteId: this.siteId },
          this.incident,
          card
        );
        if (paymentResult.success) {
          this.$gtag.purchase({
            transaction_id: this.incident.incidentId,
            affiliation: `New MAC Upgrade - Site: ${this.siteId}`,
            value: paymentResult.amount,
          });
          this.paymentSuccess = true;
          await this.$auth.identify();
        } else {
          this.paymentSuccess = false;
          this.submittingPayment = false;
          this.paymentError = true;
        }
      } catch (e) {
        console.log(e);
        this.paymentSuccess = false;
        this.submittingPayment = false;
        this.paymentError = true;
      }
    },
  },
  async mounted() {
    let company = this.$auth.activeCompany;

    if (this.$route.query.site) {
      this.siteId = this.$route.query.site;
    } else {
      let topSite = company.sites
        .filter((s) => s.listingType != "featured")
        .sort((a, b) => {
          return a.siteRank - b.siteRank;
        });
      if (topSite.length > 0) {
        this.siteId = topSite[0].siteID;
      } else {
        this.siteId = 170;
      }
    }

    this.$router.replace(`/newupgrade?site=${this.siteId}&showTop=1`);

    this.loaded = false;
  },
};
</script>
